import { useContext } from 'react';
import { BlockerContext } from '@context/blocker-provider';

export const useBlockerActive = () => {
  const context = useContext(BlockerContext);
  if (!context) {
    throw new Error('useBlockerActive must be used within a BlockerProvider');
  }
  return context;
};
