import { useQuery } from '@tanstack/react-query';
import { currentUser } from '../queries';

interface UseCurrentUserProps {
  refetch?: boolean;
}

export const useCurrentUser = ({ refetch }: UseCurrentUserProps) =>
  useQuery({
    queryKey: ['currentUser'],
    queryFn: currentUser,
    refetchInterval: refetch ? 10000 : undefined,
  });
