import { useMutation } from '@tanstack/react-query';
import { queryClient } from '@lib/axios';
import { logout } from '../queries';

export const useLogout = () =>
  useMutation({
    mutationKey: ['logout'],
    mutationFn: logout,
    onSuccess: () => queryClient.invalidateQueries(),
  });
