import { useMutation } from '@tanstack/react-query';
import { queryClient } from '@lib/axios';
import { login } from '../queries';

export const useLogin = () =>
  useMutation({
    mutationKey: ['login'],
    mutationFn: login,
    onSuccess: () => queryClient.invalidateQueries(),
  });
