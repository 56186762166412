import type { FC } from 'react';
import { createContext, useMemo, useState } from 'react';

export interface BlockerContextProps {
  isBlockerActive: boolean;
  setIsBlockerActive: (isActive: boolean) => void;
}

export const BlockerContext = createContext<BlockerContextProps | undefined>(undefined);

export const BlockerProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isBlockerActive, setIsBlockerActive] = useState(false);

  const value = useMemo(() => ({ isBlockerActive, setIsBlockerActive }), [isBlockerActive, setIsBlockerActive]);

  return <BlockerContext.Provider value={value}>{children}</BlockerContext.Provider>;
};
