import type { FC } from 'react';
import React, { memo } from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from 'src/app/routes/router';
import { BlockerProvider } from '@context/blocker-provider';

export const App: FC = memo(() => (
  <BlockerProvider>
    <RouterProvider router={router} />
  </BlockerProvider>
));

App.displayName = 'App';
