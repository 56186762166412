import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useCurrentUser } from '@lib/api/groups/queries/useCurrentUser';
import { DashboardLayout } from '@app/dashboard/DashboardLayout';
import { useBlockerActive } from '@context/hooks/useBlockerActive';
import { EAppRoute } from '../../constants';

export const PrivateRoute = () => {
  const { isSuccess: isUserSuccess, isPending } = useCurrentUser({ refetch: true });
  const { setIsBlockerActive } = useBlockerActive();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isPending && !isUserSuccess) {
      setIsBlockerActive(false);
      navigate(EAppRoute.Login);
    }
  }, [isUserSuccess, isPending, navigate, setIsBlockerActive]);

  return (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  );
};
