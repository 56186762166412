import toast from 'react-hot-toast';
import type { AxiosResponse } from 'axios';
import { isAxiosError } from 'axios';

export const handleMutationSuccess = (message: string, duration = 5000) => {
  toast.success(message, { duration });
};

interface ErrorResponse<T = unknown> extends Omit<AxiosResponse, 'data'> {
  data: T & { localize_message: string; message: string };
}

const isErrorResponse = (response?: AxiosResponse): response is ErrorResponse => {
  if (!response) return false;
  return typeof response === 'object' && true && 'data' in response;
};

interface HandleMutationErrorProps {
  error?: unknown;
  message: string;
  duration?: number;
}

export const handleMutationError = ({ error, message, duration = 5000 }: HandleMutationErrorProps) => {
  if (!error || !isAxiosError(error)) {
    return toast.error(message, { duration });
  }
  const errorResponse = error.response;

  const description = isErrorResponse(errorResponse)
    ? `${message} ${errorResponse.data.localize_message || errorResponse.data.message || ''}`
    : '';

  return toast.error(description, { duration });
};

export const executeMutation = async <TParams, TResponse>(
  mutation: (variables: TParams) => Promise<TResponse>,
  params: TParams,
  successMessage?: string,
  errorMessage?: string
) => {
  try {
    const response = await mutation(params);
    if (successMessage) handleMutationSuccess(successMessage);
    return response;
  } catch (error: unknown) {
    if (errorMessage) handleMutationError({ error, message: errorMessage });
    throw error;
  }
};
