import type { ReactNode } from 'react';
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import {
  CircleUser,
  Menu,
  User,
  Users,
  ChartNoAxesCombined,
  FileText,
  ShieldCheck,
  LogOut,
  PanelLeftOpen,
} from 'lucide-react';
import { useLogout } from '@lib/api/auth/mutations/useLogout';
import { useCurrentUser } from '@lib/api/groups/queries/useCurrentUser';
import { Button } from '@ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@ui/dropdown-menu';
import { Sheet, SheetContent, SheetTrigger } from '@ui/sheet';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { appConfig } from '@app/config';
import { EAppRoute } from '../../app/routes/constants';

interface LayoutProps {
  children: ReactNode;
}

const links = [
  { href: EAppRoute.Groups, label: 'Группы', Icon: Users },
  { href: EAppRoute.Users, label: 'Пользователи', Icon: User },
  { href: EAppRoute.Analytics, label: 'Аналитика', Icon: ChartNoAxesCombined },
  { href: EAppRoute.ActionLog, label: 'Журнал действий', Icon: FileText },
  { href: EAppRoute.Search, label: 'Фильтр поиска', Icon: ShieldCheck },
];

export const Dashboard = ({ children }: LayoutProps) => {
  const { mutate: logout } = useLogout();
  const { data: user, isLoading: isLoadingUser, isSuccess: isUserSuccess } = useCurrentUser({});
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  useEffect(() => {
    if (!isUserSuccess && !isLoadingUser) {
      navigate(EAppRoute.Login);
    }
  }, [user, isLoadingUser, navigate, isUserSuccess]);

  const logoutHandler = () => {
    logout(
      {},
      {
        onSuccess: () => {
          navigate(EAppRoute.Login);
        },
      }
    );
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => {
      const newState = !prev;
      Cookies.set('sidebarOpen', newState.toString());
      return newState;
    });
  };

  useEffect(() => {
    const savedSidebarState = Cookies.get('sidebarOpen');
    if (savedSidebarState) {
      setIsSidebarOpen(savedSidebarState === 'true');
    }
  }, []);

  return (
    <div
      className={`grid min-h-screen w-full transition-all duration-300 ${isSidebarOpen ? 'md:grid-cols-[220px_1fr] lg:grid-cols-[280px_1fr]' : 'md:grid-cols-[77px_1fr]'}`}
    >
      <div className="hidden bg-white border-r bg-muted/40 md:block">
        <div className="flex h-full max-h-screen flex-col gap-2 overflow-hidden">
          <div className={`flex h-14 mb-5 items-center border-b ${isSidebarOpen ? 'px-4' : ''} lg:h-[60px] lg:px-4`}>
            <Link to={EAppRoute.Root} className="flex items-center gap-2 font-semibold">
              <img src={appConfig.logo.primary} alt="логотип" />
              {isSidebarOpen && appConfig.features.showTitleWithLogo ? (
                <span className="font-light">{appConfig.title.toUpperCase()}</span>
              ) : null}
            </Link>
          </div>
          <div className="flex-1">
            <nav className="grid items-start px-2 text-sm font-medium lg:px-4">
              {links.map(({ href, label, Icon }) => (
                <Link
                  key={href}
                  to={href}
                  className={`${pathname.startsWith(href) ? 'bg-muted text-primary' : ''} text-nowrap flex items-center mb-2 gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary`}
                >
                  <Icon className="h-5 w-5" />
                  {isSidebarOpen && label}
                </Link>
              ))}
            </nav>
          </div>
          <div className="mt-auto mb-6">
            <div className="grid items-start px-2 text-sm font-medium lg:px-4">
              <Button variant="outline" size="icon" onClick={toggleSidebar}>
                <PanelLeftOpen className="h-5 w-5" />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col relative">
        <header className="flex h-14 bg-white items-center gap-4 border-b bg-muted/40 px-4 lg:h-[60px] lg:px-6">
          <Sheet>
            <SheetTrigger asChild>
              <Button
                variant="outline"
                size="icon"
                className="ml-auto h-10 w-10 md:hidden inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground"
              >
                <Menu className="h-4 w-4" />
                <span className="sr-only">Переключить меню навигации</span>
              </Button>
            </SheetTrigger>
            <SheetContent side="left" className="flex flex-col">
              <nav className="grid gap-2 text-lg font-medium">
                <Link to={EAppRoute.Root} className="flex items-center gap-2 font-semibold">
                  <img src={appConfig.logo.primary} alt="логотип" />
                  {appConfig.features.showTitleWithLogo ? (
                    <span className="font-light">{appConfig.title.toUpperCase()}</span>
                  ) : null}
                </Link>
                {links.map(({ href, label, Icon }) => (
                  <Link
                    key={href}
                    to={href}
                    className={`${pathname === href ? 'bg-muted text-primary' : ''} flex items-center mb-2 gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary`}
                  >
                    <Icon className="h-5 w-5" />
                    {label}
                  </Link>
                ))}
              </nav>
              <div className="mt-auto" />
            </SheetContent>
          </Sheet>
          <div className="ml-auto">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="button" size="default">
                  <span className="mr-2 font-normal">{user?.login}</span>
                  <CircleUser className="h-5 w-5" />
                  <span className="sr-only">Переключить меню пользователя</span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56" align="end">
                <DropdownMenuGroup>
                  <Link to={EAppRoute.Profile}>
                    <DropdownMenuItem>
                      <User className="mr-2 h-4 w-4" />
                      <span>Личный кабинет</span>
                    </DropdownMenuItem>
                  </Link>
                  {/* <DropdownMenuItem disabled> */}
                  {/*    <Settings className="mr-2 h-4 w-4" /> */}
                  {/*    <span>Настройки</span> */}
                  {/* </DropdownMenuItem> */}
                  <DropdownMenuSeparator />
                  <DropdownMenuItem onClick={logoutHandler}>
                    <LogOut className="mr-2 h-4 w-4" />
                    <span>Выйти</span>
                  </DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </header>
        <main className="w-full h-[calc(100vh-60px)] overflow-auto overflow-y-scroll absolute top-[60px] flex flex-1 flex-col gap-4 p-6 lg:gap-6 lg:p-6 border-gray-400 bg-muted/40">
          {children}
        </main>
      </div>
    </div>
  );
};
